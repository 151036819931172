import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Public: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className="min-h-screen flex flex-col w-full">
            <header className="flex items-center justify-between bg-gray-800 py-3 px-4 md:px-10 shadow-md w-full fixed " style={{ zIndex: 1000 }}>
                <div>
                    <div className="hidden items-center flex-1 md:block">
                        <h3 className="m-0 mr-6 whitespace-nowrap overflow-hidden text-ellipsis text-2xl font-bold text-white uppercase">
                            <a href="/" className="text-white">Jobs & Opportunities</a>
                        </h3>
                    </div>
                    <div className="block items-center flex-1 md:hidden pb-2">
                        <a href="/" className="text-white">
                            <img src={require("../../assets/logo.png")} alt="Logo" className="w-12 h-12 mx-auto object-contain" />
                        </a>
                    </div>
                </div>

                <nav className="hidden md:flex space-x-4 hover:scale-105">
                    <a href="/" className="text-white text-lg border-b-2">Jobs</a>
                </nav>

                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}></path>
                        </svg>
                    </button>
                </div>

                {isOpen && (
                    <div className="absolute top-16 left-0 right-0 bg-gray-800 md:hidden border-t-2 mt-4 ">
                        <nav className="flex flex-col space-y-4 p-4">
                            <a href="/" className="text-white text-lg">Jobs</a>
                        </nav>

                        <div className="flex justify-end border-t-2 border-white p-4">
                            <button
                                className="flex items-center justify-center w-10 h-10 bg-green-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-green-600"
                                onClick={() => window.open(process.env.REACT_APP_URL_WHATSAPP!, '_blank')}
                                >
                                <FontAwesomeIcon icon={faWhatsapp} size="xl" />
                            </button>

                            <button
                                className="flex items-center justify-center w-10 h-10 bg-blue-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-blue-600 ms-2"
                                onClick={() => window.open(process.env.REACT_APP_URL_TELEGRAM!, '_blank')}
                                >
                                <FontAwesomeIcon icon={faTelegramPlane} size="xl" />
                            </button>

                        </div>
                    </div>
                )}

                <div className="hidden space-x-4 md:flex">
                    <button
                        className="flex items-center justify-center w-10 h-10 bg-green-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-green-600"
                        onClick={() => window.open(process.env.REACT_APP_URL_WHATSAPP!, '_blank')}
                    >
                        <FontAwesomeIcon icon={faWhatsapp} size="xl" />
                    </button>

                    <button
                        className="flex items-center justify-center w-10 h-10 bg-blue-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-blue-600"
                        onClick={() => window.open(process.env.REACT_APP_URL_TELEGRAM!, '_blank')}
                    >
                        <FontAwesomeIcon icon={faTelegramPlane} size="xl" />
                    </button>
                </div>
            </header>

            <div className='bg-gray-50'>
                <Outlet />
            </div>

            <footer className="bg-gray-800 text-white pt-8 pb-4 mt-auto w-full">
                <div className="container mx-auto px-4 md:px-10">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <div className="md:col-span-1">
                            <h3 className="text-2xl font-bold mb-4">Jobs & Opportunities</h3>
                            <p className="text-gray-400">Your platform to find the best job opportunities and career resources.</p>
                        </div>

                        <div className="md:col-span-1">
                            <h4 className="text-xl font-semibold mb-4">Our team</h4>
                            <ul className="space-y-2">
                                <li>
                                    <div className="flex items-center">
                                        <a href={process.env.REACT_APP_URL_LINKEDIN_JOSE!} target='_blank' className="hover:text-blue-400">
                                            <FontAwesomeIcon icon={faLinkedin} size="xl" />
                                        </a>
                                        <span className='ps-1.5'>José Adrián Alemán Rojas</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">

                                        <a href={process.env.REACT_APP_URL_LINKEDIN_DANIEL!} target='_blank' className="hover:text-blue-400">
                                            <FontAwesomeIcon icon={faLinkedin} size="xl" />
                                        </a>
                                        <span className='ps-1.5'>Daniel Pacheco</span>
                                    </div>

                                </li>
                                <li>
                                    <div className="flex items-center">

                                        <a href={process.env.REACT_APP_URL_LINKEDIN_ALEX!} target='_blank' className="hover:text-blue-400">
                                            <FontAwesomeIcon icon={faLinkedin} size="xl" />
                                        </a>
                                        <span className='ps-1.5'>Alex González Bonilla</span>
                                    </div>

                                </li>
                            </ul>
                        </div>

                        <div className="md:col-span-1">
                            <h4 className="text-xl font-semibold mb-4">Join us</h4>
                            <div className="flex space-x-4">
                                <button
                                    className="flex items-center justify-center w-14 h-14 bg-green-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-green-600"
                                    onClick={() => window.open(process.env.REACT_APP_URL_WHATSAPP!, '_blank')}
                                    >
                                    <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                                </button>

                                <button
                                    className="flex items-center justify-center w-14 h-14 bg-blue-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-blue-600"
                                    onClick={() => window.open(process.env.REACT_APP_URL_TELEGRAM!, '_blank')}
                                    >
                                    <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className="border-t border-gray-700 mt-8 pt-4 text-center">
                        <p className="text-gray-400">&copy; {new Date().getFullYear()} Jobs & Opportunities. All rights reserved.</p>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Public;
