import { UrlParam } from "../models/urlParam.model";

export class BaseService {
    private static readonly url: string = process.env.REACT_APP_API_URL!;

    public static BuildUrl(subroute: string, ...params: UrlParam[]): string {
        let newUrl: string = this.url + subroute;
        let firstParamAdded = false;

        params.forEach((param: UrlParam) => {
            if (param.value !== "") {
                if (!firstParamAdded) {
                    newUrl += `?${param.name}=${param.value}`;
                    firstParamAdded = true;
                } else {
                    newUrl += `&${param.name}=${param.value}`;
                }
            }
        });
        return newUrl;
    }
}
