import { JobContent } from './jobContent.model';
import { JobPageable } from './jobPageable.model';
import { JobSort } from './jobSort.model'

export class JobResponse {
    constructor(
        public totalPages: number = 0,
        public totalElements: number = 0,
        public size: number = 0,
        public content: JobContent[] = [],
        public number: number = 0,
        public sort: JobSort = new JobSort(),
        public numberOfElements: number = 0,
        public pageable: JobPageable = new JobPageable(),
        public first: boolean = false,
        public last: boolean = false,
        public empty: boolean = false
    ) { }
}