export class JobShortURL {
    constructor(
        public createdDate: string = "1900-01-01",
        public createdBy: string = "",
        public id: string = "",
        public longUrl: string = "",
        public internalUrl: string = "",
        public fullShortUrl: string = ""
    ){}
}
