import { CategoryService } from './services/category.service';
import { JobService } from './services/job.service';
import React, { useEffect, useState } from 'react';
import { Category } from './models/category.model';
import { JobContent, JobResponse } from './models';
import { FilterFilled } from '@ant-design/icons';
import JobCard from './components/card';
import Swal from 'sweetalert2';
import $ from 'jquery';
import './job.css';
import ReactGA from 'react-ga4';

const Jobs: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const [spinner, setSpinner] = useState<boolean>(true);
    const [domFilter, setDomFilter] = useState<boolean>(true);
    const [blockFilter, setBlockFilter] = useState<boolean>(false);
    const [filter, setFilter] = useState<JobContent>(new JobContent())

    const [jobs, setJobs] = useState<JobResponse>(new JobResponse());
    const [categories, setCategories] = useState<Category[]>([]);
    const [subcategories, setSubCategories] = useState<string[]>([]);

    useEffect(() => {
        const Initialize = async () => {
            try {
                let categories = await CategoryService.GetCategories();

                setCategories(categories);

                if (categories.length !== 0 && categories[0]) {
                    const newFilter: JobContent = {
                        ...filter,
                        category: "",
                        subCategory: "",
                    };

                    setFilter(newFilter);

                    let jobs = await GetJobs("", "", page);
                    setJobs(jobs);

                    window.addEventListener('resize', AdjustFilter);
                }
            } catch (error: any) {
                if (error.message.includes("SHOW: ")) {
                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'info',
                        title: error.message.slice(5, error.message.length),
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                }
            }

            setSpinner(false)
        };

        Initialize();
    }, []);

    const AdjustFilter = () => {
        if (window.innerWidth >= 768) {
            if(!$("#Filters").is(":visible")){
                setDomFilter(true);
                $("#Filters").slideToggle()
            }
        } 
    };

    const GetJobs = async (category: string, subcategory: string, page: number) => {
        return await JobService.GetJobs(category, subcategory, page);
    };

    const ToggleFilters = () => {
        setDomFilter(!domFilter);
        $("#Filters").slideToggle()
    }

    const ClearFilter = async () => {
        try {
            setSpinner(true)

            setPage(1);

            let categories = await CategoryService.GetCategories();

            setCategories(categories);

            if (categories.length !== 0 && categories[0]) {
                const newFilter: JobContent = {
                    ...filter,
                    category: "",
                    subCategory: "",
                };

                setFilter(newFilter);

                let jobs = await GetJobs("", "", 1);

                setJobs(jobs);
            }
        } catch (error: any) {
            if (error.message.includes("SHOW: ")) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'info',
                    title: error.message.slice(5, error.message.length),
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
            }
        }

        setSpinner(false)
    }

    const HandleCategoryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setSpinner(true)

            setJobs(new JobResponse());

            setPage(1);

            const selected = categories.find(category => category.name === e.target.value);

            if (selected) {
                const newFilter: JobContent = {
                    ...filter,
                    category: selected.name,
                    subCategory: "",
                };

                setFilter(newFilter)
                setSubCategories(selected.subCategories);

                ReactGA.send({
                    hitType: "pageview",
                    page: "/",
                    title: "Jobs Home : " + filter.category,
                });

                let jobs = await GetJobs(selected.name, "", 1);

                setJobs(jobs);
            }else{
                const newFilter: JobContent = {
                    ...filter,
                    category: "",
                    subCategory: "",
                };

                setFilter(newFilter)

                ReactGA.send({
                    hitType: "pageview",
                    page: "/",
                    title: "Jobs Home : " + "Unselected",
                });

                let jobs = await GetJobs("", "", 1);

                setJobs(jobs);
            }
        } catch (error: any) {
            if (error.message.includes("SHOW: ")) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'info',
                    title: error.message.slice(5, error.message.length),
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
            }
        }

        setSpinner(false)
    }

    const HandleSubCategoryChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            setSpinner(true)

            setJobs(new JobResponse());

            setPage(1);

            let subcategory = e.target.value;

            const newFilter: JobContent = {
                ...filter,
                subCategory: subcategory
            };

            setFilter(newFilter)

            ReactGA.send({
                hitType: "pageview",
                page: "/",
                title: "Jobs Home : " + filter.category + " : " + subcategory,
            });

            let jobs = await GetJobs(filter.category, subcategory, 1);

            setJobs(jobs);
        } catch (error: any) {
            if (error.message.includes("SHOW: ")) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'info',
                    title: error.message.slice(5, error.message.length),
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
            }
        }

        setSpinner(false)
    }

    const HandleLoadMore = async () => {
        setBlockFilter(true);

        try {
            let newPage = page + 1;

            setPage(newPage);

            let addJobs = await GetJobs(filter.category, filter.subCategory, newPage);

            setJobs(prevJobs => ({
                ...prevJobs,
                content: [...prevJobs.content, ...addJobs.content],
                last: addJobs.last
            }));
        } catch (error: any) {
            if (error.message.includes("SHOW: ")) {
                Swal.fire({
                    toast: true,
                    position: 'top-end',
                    icon: 'info',
                    title: error.message.slice(5, error.message.length),
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer);
                        toast.addEventListener('mouseleave', Swal.resumeTimer);
                    }
                });
            }
        }

        setBlockFilter(false);
    };

    return (
        <div className="flex flex-wrap px-8 mt-6">
            {spinner && (
                <div className="spinner-container">
                    <span className="spinner"></span>
                </div>
            )}

            <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-3 block md:fixed mt-14 md:mt-10 bg-gray-50 md:bg-transparent pb-8">
                <div className="pt-3 pe-0 md:pe-8 bg-gray-50 md:bg-transparent">
                    <div className="flex justify-between items-center">
                        <h1 className="text-4xl font-bold mb-6 border-b-4 border-gray-800 text-gray-800 pb-2">
                            Jobs
                        </h1>
                        <button className="bg-blue-500 text-white p-2 rounded-md md:hidden" onClick={ToggleFilters}>
                            <FilterFilled />
                        </button>
                    </div>

                    <div id="Filters">
                        <div>
                            <h3 className="text-lg font-semibold">Category</h3>
                            <select
                                className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                onChange={HandleCategoryChange}
                                value={filter.category}
                                disabled={blockFilter}
                            >
                                <option value="">
                                    Unselected
                                </option>

                                {categories.map((category, index) => (
                                    <option key={index} value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mt-5">
                            <h3 className="text-lg font-semibold">Subcategory</h3>
                            <select
                                className="w-full mt-2 p-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                                onChange={HandleSubCategoryChange}
                                value={filter.subCategory}
                                disabled={blockFilter}
                            >
                                <option value="">
                                    Unselected
                                </option>

                                {subcategories.map((subcategory, index) => (
                                    <option key={index} value={subcategory}>
                                        {subcategory}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="mt-6">
                            <button
                                className="bg-red-600 text-white font-bold py-2 w-28 rounded hover:bg-red-700 active:scale-95 hover:scale-110"
                                onClick={ClearFilter}
                            >
                                Clear Filters
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <div className={`w-full md:w-1/2 lg:w-2/3 xl:w-3/4 px-3 ml-auto pb-8 md:mt-8 container-jobs`}>
                <div className='w-full bg-gray-50 h-12 fixed top-10 hidden md:block'></div>

                <div className={`grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4 4xl:grid-cols-4 5xl:grid-cols-4 gap-6 transition-margin ${domFilter ? "mt-2" : "mt-0 pt-0"} md:mt-14 px-2 pe-0`}>
                    {jobs.content.map((job, index) => (
                        <div key={index} className="job-card">
                            <JobCard
                                job={job}
                            />
                        </div>
                    ))}
                </div>

                {
                    (!jobs.last && jobs.content.length > 0) &&
                    (
                        <div className='flex align-middle justify-center mt-6'>
                            <button
                                className="bg-green-500 text-white font-bold py-2 mt-4 w-44 rounded hover:bg-green-600 active:scale-95 hover:scale-110"
                                onClick={HandleLoadMore}
                            >
                                Load More
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Jobs;
