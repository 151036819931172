import { JobSort } from './jobSort.model'

export class JobPageable {
    constructor(
        public offset: number = 0,
        public sort: JobSort = new JobSort(),
        public pageNumber: number = 0,
        public pageSize: number = 0,
        public paged: boolean = false,
        public unpaged: boolean = false
    ){}
}