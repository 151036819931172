import { faWhatsapp, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faThumbsUp, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JobMetrics } from '../../models/jobMetrics.model';
import { JobService } from '../../services/job.service';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JobContent } from '../../models';
import Swal from 'sweetalert2';
import $ from 'jquery';
import './detail.css'
import ReactGA from 'react-ga4';

const Detail: React.FC = () => {
    const [metrics, setMetrics] = useState<JobMetrics>(new JobMetrics());
    const [job, setJob] = useState<JobContent>(new JobContent());
    const [spinner, setSpinner] = useState<boolean>(true);
    const [like, setLike] = useState<boolean>(false);

    const navigate = useNavigate();

    const currentUrl = window.location.href;

    const shareOnWhatsApp = () => {
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;
        window.open(whatsappUrl, '_blank');
    };

    const shareOnTelegram = () => {
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`;
        window.open(telegramUrl, '_blank');
    };

    const handleAddLike = async () => {
        if(like){
            return;
        }

        const label = job.category + " : "  + job.subCategory + " : " + job.name;

        trackEvent("Like", label);

        let likes = await AddLike(job.id);

        let newMetrics = {
            ...metrics,
            LIKES: likes
        }

        setMetrics(newMetrics)

        setLike(true);

        $("#btnLike").prop("disabled", true);
        $("#btnLike").addClass("bg-gray-700"); 
        $("#btnLike").removeClass("hover:bg-blue-700"); 
        $("#btnLike").removeClass("transition-colors"); 
        $("#btnLike").removeClass("duration-300"); 
        $("#btnLike").removeClass("active:scale-95");   
        $("#btnLike").removeClass("hover:scale-110");   
        $("#btnLike").removeClass("hover:active:scale-95"); 
        $("#btnLike").off("click");
    };

    const { job_Id } = useParams<{ job_Id: string }>();

    const GetJob = async (id: string) => {
        return await JobService.GetJob(id);
    };

    const GetMetrics = async (id: string) => {
        return await JobService.GetMetrics(id);
    };

    const AddView = async (id: string) => {
        return await JobService.AddView(id);
    };

    const AddLike = async (id: string) => {
        return await JobService.AddLike(id);
    };

    const ValidateDisable = async (id: string) => {
        return await JobService.ValidateDisable(id);
    };

    const trackEvent = (category="", label="") => {
        const action = "Clicked on " + label;
        const eventTracker = () => {
          ReactGA.event({category: category, action: action, label: label});
        }
        return eventTracker;
    }

    const handleJobClick = () => {
        const label = job.category + " : "  + job.subCategory + " : " + job.name;
        trackEvent("Apply Job", label);
        setTimeout(() => {
            window.open(job.shortURL.longUrl, '_blank');
          }, 1000);

    }

    useEffect(() => {
        const Initialize = async () => {
            try {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });

                if (!job_Id) {
                    return;
                }

                let job = await GetJob(job_Id);

                ReactGA.send({
                    hitType: "pageview",
                    page: "/jobs/" + job_Id,
                    title: job.company + " : " + job.name,
                });

                setJob(job);

                let metrics = await GetMetrics(job_Id);

                metrics.VIEWS = await AddView(job_Id);

                const shouldBeLikeDisabled = await ValidateDisable(job_Id);

                if (shouldBeLikeDisabled) {
                    $("#btnLike").prop("disabled", true);
                    $("#btnLike").addClass("bg-gray-700"); 
                    $("#btnLike").removeClass("hover:bg-blue-700"); 
                    $("#btnLike").removeClass("transition-colors"); 
                    $("#btnLike").removeClass("duration-300"); 
                    $("#btnLike").removeClass("active:scale-95");   
                    $("#btnLike").removeClass("hover:scale-110");        
                    $("#btnLike").removeClass("hover:active:scale-95");       
                    $("#btnLike").off("click");      
                }

                $("#btnView").off("click");

                setMetrics(metrics);

                const textareas = document.querySelectorAll("textarea");

                textareas.forEach((textarea) => {
                    const element = textarea as HTMLTextAreaElement;

                    element.style.height = element.scrollHeight + "px";
                    element.style.overflowY = "hidden";
                    element.style.maxHeight = element.scrollHeight + "px";
                    element.style.minHeight = element.scrollHeight + "px";

                    element.style.border = "none";
                    element.style.backgroundColor = "transparent";
                    element.style.resize = "none";
                    element.style.outline = "none";
                    element.style.color = "inherit";
                    element.style.font = "inherit";
                });

                setSpinner(false)
            } catch (error: any) {
                if (error.message.includes("SHOW: ")) {
                    navigate(`/job`);

                    Swal.fire({
                        toast: true,
                        position: 'top-end',
                        icon: 'info',
                        title: error.message.slice(5, error.message.length),
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer);
                            toast.addEventListener('mouseleave', Swal.resumeTimer);
                        }
                    });
                }

                setSpinner(false)
            }

        };

        Initialize();
    }, []);

    return (
        <div className='pb-10 pt-24 px-6 sm:pt-28 sm:px-20 xl:pt-36 xl:px-60 w-full'>
            {spinner && (
                <div className="spinner-container">
                    <span className="spinner"></span>
                </div>
            )}

            <div className='w-full bg-gray-50 h-12 fixed top-10 block md:hidden'></div>

            <p className='text-3xl md:text-6xl'>{job.name}</p>
            <div className='mt-10 space-y-4'>
                <div className='flex'>
                    <span className='font-semibold w-32'>Company:</span>
                    <span>{job.company}</span>
                </div>
                <div className='flex'>
                    <span className='font-semibold w-32'>Location:</span>
                    <span>{job.location}</span>
                </div>
                <div className='flex'>
                    <span className='font-semibold w-32'>Category:</span>
                    <span>{job.category}</span>
                </div>
                <div className='flex'>
                    <span className='font-semibold w-32'>Subcategory:</span>
                    <span>{job.subCategory}</span>
                </div>
                <div className='flex'>
                    <span className='font-semibold w-32'>Posted on:</span>
                    <span>{job.postedDate}</span>
                </div>
            </div>

            <p className='text-3xl md:text-xl mt-10 font-bold'>Job Description</p>

            <hr className="border-t border-gray-300 my-4" />

            <div className='mt-4 w-full h-auto'>
                <textarea
                    className='w-full h-auto'
                    value={job.description}
                    readOnly
                />
            </div>
            <div className="mt-6 block xs:flex xs:justify-between xs:align-middle">
                <div className='flex items-center align-middle xs:order-2'>
                    <div>
                        <button
                            className="flex items-center space-x-2 bg-gray-700 text-white py-2 px-4 rounded-full transition-colors duration-300 focus:outline-none shadow-lg transform"
                            id="btnView"
                            disabled
                        >
                            <FontAwesomeIcon icon={faEye} className="text-white" size="lg" />
                            <span className="font-semibold text-white">{metrics.VIEWS}</span>
                        </button>
                    </div>
                    {<div className='ml-2'>
                        <button
                            className="flex items-center space-x-2 bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300 focus:outline-none shadow-lg transform active:scale-95 hover:scale-110"
                            onClick={handleAddLike}
                            id="btnLike"
                        >
                            <FontAwesomeIcon icon={faThumbsUp} className="text-white" size="lg" />
                            <span className="font-semibold text-white">{metrics.LIKES}</span>
                        </button>
                    </div>}
                </div>
                <div className='flex mt-4 xs:order-1 xs:mt-0'>
                    <div>
                        <span className='font-semibold w-32'>Share on:</span>

                        <div className='flex mt-2'>
                            <button
                                onClick={shareOnWhatsApp}
                                className="flex items-center justify-center w-14 h-14 bg-green-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-green-600"
                            >
                                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
                            </button>

                            <button
                                onClick={shareOnTelegram}
                                className="flex items-center justify-center w-14 h-14 bg-blue-500 text-white rounded-full shadow-lg transform transition-transform duration-300 active:scale-95 hover:scale-110 hover:bg-blue-600 ml-2"
                            >
                                <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-6'>
                <button
                    className="w-full md:w-fit bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300 active:scale-95"
                    onClick={handleJobClick}
                >
                    Apply now
                </button>
                {
                    //<button
                    //    className="w-full md:w-fit bg-gray-500 hover:bg-gray-600 text-white font-semibold py-3 px-5 rounded-full shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300 active:scale-95 mt-4 ms-0 md:mt-0 md:ms-2"
                    //    onClick={() => window.location.href = "/"}
                    //>
                    //    Back to jobs
                    //</button>
                }
            </div>
        </div>
    );
};

export default Detail;