import { Category } from "../models/category.model";

export class CategoryService {
    static async GetCategories(): Promise<Category[]> {
        try {
            const url = process.env.REACT_APP_API_URL!

            const response = await fetch(`${url}/public/jobs/categories`);

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            let json = await response.json()

            return json as Category[];
        } catch (error: any) {
            throw error
        }
    }

}