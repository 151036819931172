import React from 'react';
import { useNavigate } from 'react-router-dom';
import { JobContent } from '../models';
import ReactGA from 'react-ga4';

interface props {
    job: JobContent
}

const Component: React.FC<props> = ({ job }: { job: JobContent }) => {
    const handleLearnMore = () => {
        const label = job.category + " : "  + job.subCategory + " : " + job.name;
        trackEvent("Learn More", label);
        window.open(`/#/jobs/${job.id}`, '_blank');
    };

    const trackEvent = (category="", label="") => {
        const action = "Clicked on " + label;
        const eventTracker = () => {
            console.log('Called');
          ReactGA.event({category, action, label});
          console.log('Called');
        }
        return eventTracker;
    }

    const handleJobClick = () => {
        const label = job.category + " : "  + job.subCategory + " : " + job.name;
        trackEvent("Apply Job", label);
        
        setTimeout(() => {
            window.open(job.shortURL.longUrl, '_blank');
          }, 1000);

    }


    return (
        <div className="bg-white shadow-lg rounded-lg h-full flex flex-col justify-between">
            <div>
                <div className="bg-blue-500 text-white font-bold text-lg rounded-t-lg p-2 flex items-center justify-center h-20 ">
                    <span className="block w-full text-center">
                        {job.name.length > 30 ? `${job.name.slice(0, 30)}...` : job.name}
                    </span>
                </div>

                <div className="px-6 pt-4">
                    <span className="font-bold mb-2">Company</span>
                    <span className="ms-2">
                        {job.company}
                    </span>
                </div>
                <div className="px-6 pt-2">
                    <span className="font-bold mb-2">Posted On:</span>
                    <span className="ms-2">
                        {job.postedDate}
                    </span>
                </div>
                <div className="px-6 pt-2">
                    <p className="font-bold mb-2">Description</p>
                    <p className="min-h-44 xs:min-h-64 sm:min-h-32 lg:min-h-42 xl:min-h-40 2xl:min-h-40">
                        {job.description.length > 130 ? `${job.description.slice(0, 130)}...` : job.description}
                    </p>
                </div>
            </div>
            <div className="px-6 pb-4 block md:flex align-middle justify-end">
                <button
                    className=" h-10 bg-blue-500 text-white font-bold py-2 mt-4 w-full rounded hover:bg-blue-600 active:scale-95 hover:scale-105"
                    onClick={handleJobClick}
                >
                    Apply now
                </button>

                <button
                    className=" h-10 bg-blue-500 text-white font-bold py-2 mt-4 w-full rounded hover:bg-blue-600 active:scale-95 hover:scale-105 md:ms-2"
                    onClick={handleLearnMore}
                >
                    Learn More
                </button>
            </div>
        </div>
    );
};

export default Component;
