import { JobShortURL } from "./JobShortUrl.model";

export class JobContent {
    constructor(
        public createdDate: string = "1900-01-01",
        public createdBy: string = "",
        public id: string = "",
        public externalId: string = "",
        public name: string = "",
        public category: string = "",
        public subCategory: string = "",
        public description: string = "",
        public location: string = "",
        public image: string = "",
        public company: string = "",
        public email: string = "",
        public postedDate: string = "1900-01-01",
        public featured: boolean = false,
        public shortURL: JobShortURL = new JobShortURL()
    ){}
}