import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Detail from './public/jobs/components/detail/detail';
import Job from './public/jobs/job';
import Public from './public';
import React from 'react';
import ReactGA from 'react-ga4';
import { debug } from 'console';

ReactGA.initialize(
  "G-6Q9ZTMVVV0",
  { 
    testMode: false  
  }
);



const App: React.FC = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Public />} >
          <Route path="/" element={<Job />} />
          <Route path="/jobs/:job_Id" element={<Detail />} />
        </Route>
        
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;
